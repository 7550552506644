<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        @click="edit"
        :disabled="!photo"
        v-on="on"
      >
        <v-icon>fa fa-edit</v-icon>
      </v-btn>
    </template>

    <span>{{'photo.action.edit_name'| t}}</span>
  </v-tooltip>
</template>

<script>
import photoItemAction from '@/components/photoSelector/action/photoItemAction'
export default {
  mixins: [photoItemAction],
  methods: {
    edit() {
      this.$apopup.prompt({
        title: this.$t('action.edit'),
        defaultValue: this.photo.name,
        promptMaxLength: 100,
        applyCallback: data => this.$emit('updatePhotoName', data)
      })
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>